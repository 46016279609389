import { useEffect, useState } from "react";

import { formatDate } from "../utils/format_date";
import ExperienceItem from "../components/experience";
import RefreeItem from "../components/refreeItem";
import {
  getInquiries,
  updateInquiry,
} from "../controllers/inquiriesController";
import { timeAgo } from "../utils/time_ago";
import NoData from "../components/noData";
import { getConsultationRequests } from "../controllers/consultationsController";
import CustomModal from "../components/customModal";

const Dashboardconsultance = () => {
  const [inquiries, setInquiries] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [keyword, setkeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);

  useEffect(() => {
    setLoading(true);
    getConsultationRequests().then((data) => {
      setInquiries(data);
      setLoading(false);
    });
  }, [refresh]);
  return loading ? (
    <div className="flex   ms-auto w-full  justify-center items-center h-96">
      <div className="border-4 rounded-full border-textColor h-8 w-8 animate-spin border-t-transparent "></div>
    </div>
  ) : (
    <div>
      <div className="flex justify-between">
        <h1 className="text-lg ">Consultancy Requests</h1>
        <input
          onChange={(e) => {
            setkeyword(e.target.value);
          }}
          placeholder="Search here"
          className="border-borderColor rounded-md focus:ring-primary focus:border-primary"
        />
      </div>
      <div className="bg-white p-5 border border-borderColor py-5 rounded-md mt-5">
        {inquiries.length < 1 ? (
          <NoData />
        ) : (
          <table className=" w-full">
            <thead className="px-5">
              <tr className="border-b border-borderColor border-opacity-70">
                <th className="text-start font-normal text-sm pb-2 text-muted">
                  Sent
                </th>
                <th className="text-start font-normal text-sm pb-2 text-muted">
                  Full name
                </th>
                <th className="text-start font-normal text-sm pb-2 text-muted">
                  Email
                </th>
                <th className="text-start font-normal text-sm pb-2 text-muted">
                  Phone
                </th>
                <th className="text-start font-normal text-sm pb-2 text-muted">
                  Service
                </th>
                <th className="text-start font-normal text-sm pb-2 text-muted">
                  Date
                </th>
                <th className="text-start font-normal text-sm pb-2 text-muted">
                  Time
                </th>
                {/* <th className="text-start">Service</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody className="mt-3">
              {inquiries
                .filter((item) =>
                  item.fullName.toLowerCase().includes(keyword.toLowerCase())
                )
                .map((item) => (
                  <tr className={`cursor-pointer hover:opacity-100`}>
                    <td className="py-3 text-sm">
                      {timeAgo(item.createdAt.toDate())}
                    </td>
                    <td className="py-3 text-sm">{item.fullName}</td>
                    <td className="py-3 text-sm">{item.email}</td>
                    <td className="py-3 text-sm">{item.phone}</td>
                    <td className="py-3 text-sm">{item.service}</td>
                    <td className="py-3 text-sm">{item.date}</td>
                    <td className="py-3 text-sm">{item.time}</td>
                    {/* <td className="py-2">{item.service}</td> */}
                    <td>
                      <button
                        onClick={() => {
                          setselectedItem(item);
                          setShowModal(true);
                        }}
                        className="py-1 px-3 bg-orange-100 text-sm  rounded-full"
                      >
                        Additional Information
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
            <CustomModal
              show={showModal}
              heading={"Additional Information"}
              setShow={setShowModal}
              content={
                <div>
                  <p>{selectedItem && selectedItem.additionalInfo}</p>
                </div>
              }
            />
          </table>
        )}
      </div>
    </div>
  );
};

export default Dashboardconsultance;
