import React, { useRef, useState } from "react";
import CustomButton from "../components/customButton";
import toast from "react-hot-toast";
import { addConsultationRequest } from "../controllers/consultationsController";

const BookAConsultance = () => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(formRef.current);

    const data = {
      fullName: formData.get("fullName"),
      email: formData.get("email"),
      phone: formData.get("phone"),
      date: formData.get("date"),
      time: formData.get("time"),
      service: formData.get("service"),
      additionalInfo: formData.get("additionalInfo"),
      referral: formData.get("referral"),
      consent: formData.get("consent") === "on", // Checkbox values are "on" or undefined
    };

    try {
      const response = await addConsultationRequest(data);
      setLoading(false);

      toast.success("Consultation booked successfully!");
      formRef.current.reset(); // Clear the form after successful submission
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setLoading(false);

      toast.error(
        "There was an error booking your consultation. Please try again."
      );
    }
  };

  return (
    <div>
      <div className="w-full h-48 bg-primary flex justify-center items-center pt-20">
        <h1 className="text-white text-4xl">Book a Consultation</h1>
      </div>
      <div className="w-11/12 md:w-4/12 2xl:w-4/12 mx-auto my-8">
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="grid grid-cols-1 md:grid-cols-2 gap-4"
        >
          {/* Full Name */}
          <div className="col-span-1">
            <label htmlFor="fullName">Full Name</label>
            <input
              id="fullName"
              name="fullName"
              type="text"
              placeholder="Enter your full name"
              className="form-style"
              required
            />
          </div>

          {/* Email Address */}
          <div className="col-span-1">
            <label htmlFor="email">Email Address</label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Enter your email address"
              className="form-style"
              required
            />
          </div>

          {/* Phone Number */}
          <div className="col-span-1">
            <label htmlFor="phone">Phone Number</label>
            <input
              id="phone"
              name="phone"
              type="tel"
              placeholder="Enter your phone number"
              className="form-style"
              required
            />
          </div>

          {/* Preferred Consultation Date and Time */}
          <div className="col-span-1 md:col-span-1">
            <label htmlFor="date">Preferred Date</label>
            <input
              id="date"
              name="date"
              type="date"
              className="form-style"
              required
            />
          </div>
          <div className="col-span-1 md:col-span-1">
            <label htmlFor="time">Preferred Time</label>
            <input
              id="time"
              name="time"
              type="time"
              className="form-style"
              required
            />
          </div>

          {/* Consultation Type */}
          <div className="col-span-2">
            <label htmlFor="service">
              Select the Service You Are Interested In
            </label>
            <select id="service" name="service" className="form-style" required>
              <option value="HR Advisory">HR Advisory</option>
              <option value="HR IT Consulting">HR IT Consulting</option>
              <option value="Talent Management">Talent Management</option>
              <option value="Recruitment">Recruitment</option>
              <option value="Training and Development">
                Training and Development
              </option>
              <option value="Other">Other</option>
            </select>
          </div>

          {/* Additional Information */}
          <div className="col-span-2">
            <label htmlFor="additionalInfo">Additional Information</label>
            <textarea
              id="additionalInfo"
              name="additionalInfo"
              placeholder="Please provide any additional details or questions you have for us"
              className="form-style"
            ></textarea>
          </div>

          {/* Availability */}
          <div className="col-span-2">
            <label htmlFor="referral">How did you hear about us?</label>
            <select
              id="referral"
              name="referral"
              className="form-style"
              required
            >
              <option value="Website">Website</option>
              <option value="Referral">Referral</option>
              <option value="Social Media">Social Media</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {/* Consent */}
          <div className="col-span-2">
            <label>
              <input
                type="checkbox"
                id="consent"
                name="consent"
                className="mr-2"
              />
              I agree to the{" "}
              <a href="/privacy" target="_blank" className="text-primary">
                Privacy Policy
              </a>{" "}
              and{" "}
              <a href="/terms" target="_blank" className="text-primary">
                Terms of Service
              </a>
              .
            </label>
          </div>

          {/* Submit Button */}
          <div className="col-span-2">
            <CustomButton loading={loading} title={"Book now"} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookAConsultance;
