import { useEffect, useState } from "react";
import { timeAgo } from "../utils/time_ago";
import NoData from "../components/noData";
import { getDemoRequests } from "../controllers/demoRequestsController";
import CustomModal from "../components/customModal";

const DashboardDemo = () => {
  const [demos, setDemos] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [keyword, setkeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);

  useEffect(() => {
    setLoading(true);
    getDemoRequests().then((data) => {
      setDemos(data);
      setLoading(false);
    });
  }, [refresh]);
  return loading ? (
    <div className="flex   ms-auto w-full  justify-center items-center h-96">
      <div className="border-4 rounded-full border-textColor h-8 w-8 animate-spin border-t-transparent "></div>
    </div>
  ) : (
    <div>
      <div className="flex justify-between">
        <h1 className="text-lg ">Demo Requests</h1>
        <input
          onChange={(e) => {
            setkeyword(e.target.value);
          }}
          placeholder="Search here"
          className="border-borderColor rounded-md focus:ring-primary focus:border-primary"
        />
      </div>
      <div className="bg-white p-5 border border-borderColor py-5 rounded-md mt-5">
        {demos.length < 1 ? (
          <NoData />
        ) : (
          <table className=" w-full">
            <thead className="px-5">
              <tr className="border-b border-borderColor border-opacity-70">
                <th className="text-start font-normal text-sm pb-2 text-muted">
                  Sent
                </th>
                <th className="text-start font-normal text-sm pb-2 text-muted">
                  Full name
                </th>{" "}
                <th className="text-start font-normal text-sm pb-2 text-muted">
                  Company
                </th>
                <th className="text-start font-normal text-sm pb-2 text-muted">
                  Email
                </th>
                <th className="text-start font-normal text-sm pb-2 text-muted">
                  Phone
                </th>{" "}
                <th className="text-start font-normal text-sm pb-2 text-muted">
                  Date
                </th>{" "}
                <th className="text-start font-normal text-sm pb-2 text-muted">
                  Time
                </th>
                {/* <th className="text-start">Service</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody className="mt-3">
              {demos
                .filter((item) =>
                  item.fullName.toLowerCase().includes(keyword.toLowerCase())
                )
                .map((item) => (
                  <tr className={`cursor-pointer hover:opacity-100`}>
                    <td className="py-3 text-sm">
                      {timeAgo(item.createdAt.toDate())}
                    </td>
                    <td className="py-3 text-sm">{item.fullName}</td>
                    <td className="py-3 text-sm">{item.companyName}</td>
                    <td className="py-3 text-sm">{item.email}</td>
                    <td className="py-3 text-sm">{item.phoneNumber}</td>
                    <td className="py-3 text-sm">{item.preferredDate}</td>
                    <td className="py-3 text-sm">{item.preferredTime}</td>
                    {/* <td className="py-2">{item.service}</td> */}
                    <td>
                      <button
                        onClick={() => {
                          setselectedItem(item);
                          setShowModal(true);
                        }}
                        className="py-1 px-3 bg-orange-100 text-sm  rounded-full"
                      >
                        More Info
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
            <CustomModal
              show={showModal}
              setShow={setShowModal}
              heading={"More information"}
              content={
                <div className="space-y-2">
                  {selectedItem &&
                    [
                      {
                        title: "Areas of Interest",
                        value: selectedItem.areasOfInterest,
                      },
                      {
                        title: "Company Size",
                        value: selectedItem.companySize,
                      },
                      { title: "HR System", value: selectedItem.hrSystem },
                      { title: "industry", value: selectedItem.industry },
                      { title: "Comments", value: selectedItem.comments },
                    ].map((item) => {
                      return (
                        <div className="flex space-x-4">
                          <p className="text-muted">{item.title}:</p>
                          <p>{item.value}</p>
                        </div>
                      );
                    })}
                </div>
              }
            />
          </table>
        )}
      </div>
    </div>
  );
};

export default DashboardDemo;
