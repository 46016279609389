import toast from "react-hot-toast";
import CustomButton from "../components/customButton";
import { useState } from "react";
import { addDemoRequest } from "../controllers/demoRequestsController";

const BookADemo = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Access form data using FormData API
    const formData = new FormData(e.target);

    // Extract the values from the form
    const data = {
      fullName: formData.get("fullName"),
      companyName: formData.get("companyName"),
      jobTitle: formData.get("jobTitle"),
      email: formData.get("email"),
      phoneNumber: formData.get("phoneNumber"),
      companySize: formData.get("companySize"),
      industry: formData.get("industry"),
      hrSystem: formData.get("hrSystem"),
      preferredDate: formData.get("preferredDate"),
      preferredTime: formData.get("preferredTime"),
      areasOfInterest: formData.get("areasOfInterest"),
      howDidYouHear: formData.get("howDidYouHear"),
      comments: formData.get("comments"),
      consent: formData.get("consent") === "on",
    };

    // Check if consent is given
    if (!data.consent) {
      toast.error("You must agree to the Privacy Policy and Terms of Service.");
      return;
    }

    try {
      setLoading(true);
      await addDemoRequest(data);
      toast.success("Demo request submitted successfully!");
      e.target.reset(); // Reset the form after submission
    } catch (error) {
      console.error("Error submitting demo request:", error);
      toast.error("Failed to submit demo request. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="w-full h-48 bg-primary flex justify-center items-center pt-20">
        <h1 className="text-white text-4xl">Schedule a demo</h1>
      </div>
      <div className="w-11/12 md:w-4/12 2xl:w-4/12 mx-auto my-8">
        <form
          className="grid grid-cols-1 md:grid-cols-2 gap-4"
          onSubmit={handleSubmit}
        >
          {/* Contact Information */}
          <div className="col-span-1">
            <label>Full Name</label>
            <input
              name="fullName"
              placeholder="Enter your full name"
              className="form-style"
              required
            />
          </div>
          <div className="col-span-1">
            <label>Company Name</label>
            <input
              name="companyName"
              placeholder="Enter your company's name"
              className="form-style"
              required
            />
          </div>
          <div className="col-span-1">
            <label>Job Title</label>
            <input
              name="jobTitle"
              placeholder="Enter your job title"
              className="form-style"
              required
            />
          </div>
          <div className="col-span-1">
            <label>Email Address</label>
            <input
              type="email"
              name="email"
              placeholder="Enter your email address"
              className="form-style"
              required
            />
          </div>
          <div className="col-span-1">
            <label>Phone Number</label>
            <input
              type="tel"
              name="phoneNumber"
              placeholder="Enter your phone number"
              className="form-style"
              required
            />
          </div>

          {/* Company Details */}
          <div className="col-span-1">
            <label>Company Size</label>
            <select name="companySize" className="form-style" required>
              <option value="1-50">1-50</option>
              <option value="51-200">51-200</option>
              <option value="201-500">201-500</option>
              <option value="501-1000">501-1000</option>
              <option value="1000+">1000+</option>
            </select>
          </div>
          <div className="col-span-1">
            <label>Industry</label>
            <select name="industry" className="form-style" required>
              <option value="Technology">Technology</option>
              <option value="Healthcare">Healthcare</option>
              <option value="Finance">Finance</option>
              <option value="Manufacturing">Manufacturing</option>
              <option value="Education">Education</option>
              <option value="Other">Other (please specify)</option>
            </select>
          </div>
          <div className="col-span-2">
            <label>Current HR System</label>
            <textarea
              name="hrSystem"
              placeholder="Describe your current HR system or process"
              className="form-style"
            ></textarea>
          </div>

          {/* Demo Preferences */}
          <div className="col-span-2 md:col-span-1">
            <label>Preferred Date</label>
            <input
              type="date"
              name="preferredDate"
              className="form-style"
              required
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <label>Preferred Time</label>
            <input
              type="time"
              name="preferredTime"
              className="form-style"
              required
            />
          </div>
          <div className="col-span-2">
            <label>Specific Areas of Interest</label>
            <textarea
              name="areasOfInterest"
              placeholder="e.g., Recruitment, Payroll, Employee Self-Service"
              className="form-style"
            ></textarea>
          </div>

          {/* Additional Information */}
          <div className="col-span-2">
            <label>How Did You Hear About Us?</label>
            <select name="howDidYouHear" className="form-style" required>
              <option value="Website">Website</option>
              <option value="Referral">Referral</option>
              <option value="Social Media">Social Media</option>
              <option value="Advertisement">Advertisement</option>
              <option value="Other">Other (please specify)</option>
            </select>
          </div>
          <div className="col-span-2">
            <label>Comments or Questions</label>
            <textarea
              name="comments"
              placeholder="Enter any additional comments or questions"
              className="form-style"
            ></textarea>
          </div>

          {/* Consent */}
          <div className="col-span-2">
            <label>
              <input type="checkbox" name="consent" className="mr-2" required />
              I agree to the{" "}
              <a href="/privacy" target="_blank" className="text-primary">
                Privacy Policy
              </a>{" "}
              and{" "}
              <a href="/terms" target="_blank" className="text-primary">
                Terms of Service
              </a>
              .
            </label>
          </div>

          {/* Submit Button */}
          <div className="col-span-2">
            <CustomButton loading={loading} title="Schedule a Demo" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookADemo;
