import { useLocation, useNavigate } from "react-router-dom";
import { getUser } from "../controllers/authController";
import { auth } from "../utils/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import {
  AiOutlineAim,
  AiOutlineBehance,
  AiOutlineContacts,
  AiOutlineDesktop,
  AiOutlineDocker,
  AiOutlineFile,
  AiOutlineFundView,
  AiOutlineHome,
  AiOutlineMail,
  AiOutlineSchedule,
  AiOutlineSkype,
  AiOutlineUser,
  AiOutlineUserSwitch,
} from "react-icons/ai";
const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getUser(user.email).then((data) => {
          setUser(data);
        });
      } else {
        setUser(null);
      }
    });
  }, []);
  return (
    user && (
      <div>
        <div className="px-5">
          <div
            onClick={() => {
              navigate("/");
            }}
            className="text-xl font-medium  mt-2 text-white cursor-pointer"
          >
            OPM HR
          </div>
        </div>
        <div className="mt-11 text-sm space-y-4">
          {[
            {
              title: "Dashboard",
              roles: ["Admin"],
              path: "/dashboard",
              icon: <AiOutlineHome className="text-xl" />,
            },
            {
              title: "Users",
              roles: ["Admin"],
              path: "/dashboard/users",
              icon: <AiOutlineUser className="text-xl" />,
            },
            {
              title: "Companies",
              roles: ["Admin"],
              path: "/dashboard/companies",
              icon: <AiOutlineMail className="text-xl" />,
            },
            {
              title: "Curliculum Vitae",
              roles: ["User"],
              path: "/dashboard/cv",
              icon: <AiOutlineDocker className="text-xl" />,
            },
            {
              title: "Company Profile",
              roles: ["Company"],
              path: "/dashboard/companyProfile",
              icon: <AiOutlineFile className="text-xl" />,
            },
            {
              title: "Job posts",
              roles: ["Admin", "Company"],
              path: "/dashboard/jobPosts",
              icon: <AiOutlineFundView className="text-xl" />,
            },
            {
              title: "Unpublished Jobs",
              roles: ["Admin"],
              path: "/dashboard/unpublishedJobs",
              icon: <AiOutlineFundView className="text-xl" />,
            },
            {
              title: "Published Jobs",
              roles: ["Company"],
              path: "/dashboard/publishedJobs",
              icon: <AiOutlineBehance className="text-xl" />,
            },
            {
              title: "Job applications",
              roles: ["Admin", "Company", "User"],
              path: "/dashboard/applications",
              icon: <AiOutlineContacts className="text-xl" />,
            },
            {
              title: "Inquiries",
              roles: ["Admin"],
              path: "/dashboard/inquiries",
              icon: <AiOutlineMail className="text-xl" />,
            },
            {
              title: "Consultancy Requests",
              roles: ["Admin"],
              path: "/dashboard/consultance",
              icon: <AiOutlineDesktop className="text-xl" />,
            },
            {
              title: "Demo Requests",
              roles: ["Admin"],
              path: "/dashboard/demo",
              icon: <AiOutlineSchedule className="text-xl" />,
            },
            {
              title: "Subscribers",
              roles: ["Admin", "Marketing"],
              path: "/dashboard/subscribers",
              icon: <AiOutlineUserSwitch className="text-xl" />,
            },
            {
              title: "Blog posts",
              roles: ["Admin", "Marketing"],
              path: "/dashboard/blog",
              icon: <AiOutlineSkype className="text-xl" />,
            },
          ].map(
            (item, index) =>
              item.roles.includes(user.role) && (
                <div
                  onClick={() => {
                    navigate(item.path);
                  }}
                  className={`${
                    pathname == item.path
                      ? "bg-primary hover:bg-opacity-90 rounded-lg text-sm text-white"
                      : " text-white  opacity-70 text-sm hover:bg-[#393D3D] rounded-lg"
                  }  flex space-x-2 px-5 py-2 items-center cursor-pointer transition-all `}
                >
                  {item.icon}
                  <h1 className="text-base">{item.title}</h1>
                </div>
              )
          )}
        </div>
      </div>
    )
  );
};

export default Sidebar;
