import { motion } from "framer-motion";
import { NavbarContext } from "../../layouts/mainLayout";
import { useContext, useEffect } from "react";
import { GrDocumentPerformance, GrSteps } from "react-icons/gr";
import { GoGoal } from "react-icons/go";
import { FcFeedback } from "react-icons/fc";
import { VscFeedback } from "react-icons/vsc";
import { MdOutlineModelTraining } from "react-icons/md";
import { LuDatabase } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
const ESSPortal = () => {
  const navigate = useNavigate();

  const scrollAnimationVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };
  const { isScrolling, setIsScrolling, setPathname } =
    useContext(NavbarContext);
  useEffect(() => {
    setIsScrolling(true);
    setPathname("/performancemanagementSoftware");
  }, []);
  return (
    <div>
      <motion.div
        whileInView="visible"
        initial="hidden"
        className="w-10/12 2xl:w-8/12 mx-auto grid grid-cols-12 items-center pt-28"
      >
        <div className=" col-span-6">
          <img src="/product.png" />
        </div>
        <div className=" col-span-6">
          <h1 className="font-medium text-textColor text-4xl">ESS portal</h1>
          <p className="text-lg text-muted  mt-4">
            ESS Portal is a web-based user interface for employees used to
            access and edit information. It enables the employees to edit and
            upload their personal details, documents, and other information into
            the system, making them feel more valued and empowered.
          </p>
          <div className="space-x-3">
            <button
              onClick={() => {
                navigate("/bookADemo");
              }}
              className=" hover:scale-105 transition-all  duration-200 cursor-pointer bg-primary  font-medium text-white py-4 px-6 mt-8 rounded-full"
            >
              Schedule a demo
            </button>
            <button
              onClick={() => {
                navigate("/bookAConsultance");
              }}
              className=" hover:scale-105 transition-all  duration-200 cursor-pointer border border-primary text-primary  font-medium py-4 px-6 mt-8 rounded-full"
            >
              Book a consultation
            </button>
          </div>
        </div>
      </motion.div>
      <motion.div
        whileInView="visible"
        initial="hidden"
        className="bg-lightBackground py-16"
      >
        <div className="flex justify-center">
          <h1 className="text-4xl font-medium pb-12">Software features</h1>
        </div>
        <div className="w-10/12 2xl:w-8/12 grid grid-cols-3 gap-8 mx-auto ">
          {[
            {
              icon: <GrDocumentPerformance />,
              title: "Role-based Portal",
              description:
                "Different portal views and access levels maintain a unified experience across employee categories and designations.",
            },

            {
              icon: <VscFeedback />,

              title: "Payslip & Worksheets",
              description:
                "Download your latest payslip, worksheet, Form 16, and more directly from the portal using payroll software without disturbing HR or your administrator.",
            },
            {
              icon: <MdOutlineModelTraining />,

              title: "Tax Declaration & Computation",
              description:
                "Dedicated tax calculator for old and new tax regimes to compute accurate taxes, ensuring complete statutory compliance using HRMS Payroll.",
            },
          ].map((item) => {
            return (
              <div className="hover:scale-105 transition-all p-5 rounded-lg flex space-x-4 bg-white shadow-lg duration-200 cursor-pointer">
                <div className=" text-2xl text-primary mt-2">{item.icon}</div>
                <div>
                  <h1 className=" font-normal text-textColor text-xl ">
                    {item.title}
                  </h1>
                  <p className="text-base text-muted mt-1">
                    {item.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </motion.div>
      <motion.div
        variants={scrollAnimationVariants}
        whileInView="visible"
        initial="hidden"
        className=" py-16 w-10/12 2xl:w-8/12 mx-auto p-8 rounded-2xl shadow-2xl bg-primary grid grid-cols-12 my-16"
      >
        <div className=" col-span-12 flex flex-col items-center">
          <h1 className=" text-2xl text-white font-medium">
            Are you curious about this software ?
          </h1>
          <button
            onClick={() => {
              navigate("/bookAConsultance");
            }}
            className=" hover:scale-105 transition-all  duration-200 cursor-pointer bg-white font-medium  py-4 px-6 mt-8 rounded-full"
          >
            Schedule a demo
          </button>
        </div>
        <div className=" col-span-4"></div>
      </motion.div>
    </div>
  );
};

export default ESSPortal;
