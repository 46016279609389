import { AiOutlinePartition } from "react-icons/ai";
import { BiWorld } from "react-icons/bi";
import { BsBarChartSteps, BsPeople } from "react-icons/bs";
import { FaAddressBook, FaCompress, FaToolbox, FaUser } from "react-icons/fa";
import { FaArrowUpRightDots, FaGauge } from "react-icons/fa6";
import { GiExpense } from "react-icons/gi";
import { IoDuplicateOutline, IoFileTrayFullOutline } from "react-icons/io5";
import { LuBadgeHelp, LuGaugeCircle } from "react-icons/lu";
import { MdOutlineIndeterminateCheckBox, MdOutlineModelTraining, MdSettingsInputComponent } from "react-icons/md";
import { PiArticleNyTimesBold } from "react-icons/pi";
import {
  RiBarChartGroupedLine,
  RiCalendarCheckLine,
  RiSecurePaymentLine,
} from "react-icons/ri";
import { TbTransform } from "react-icons/tb";

export const sectors = [
  "IT & Technology",
  "Human Resource (HR)",
  "Business Education",
  "Finance & Accounting",
  "Marketing & Advertising",
  "Healthcare",
  "Engineering",
  "Hospitality & Tourism",
  "Sales & Retail",
  "Art & Design",
  "Manufacturing & Production",
  "Legal",
  "Media & Communications",
  "Nonprofit & Social Services",
  "Government & Public Administration",
  "Consulting",
  "Real Estate",
  "Transportation & Logistics",
  "Research & Development",
  "Customer Service",
  "Science",
  "Writing & Editing",
  "Sports & Recreation",
  "Fashion & Beauty",
  "Agriculture & Forestry",
  "Insurance",
  "Architecture",
  "Telecommunications",
  "Energy & Utilities",
  "Environment & Sustainability",
  "Pharmaceuticals",
  "Information Services",
  "Security & Defense",
  "Entertainment",
  "Food & Beverage",
  "Automotive",
  "Aviation",
  "Insurance",
  "Childcare & Early Education",
  "Supply Chain Management",
  "E-commerce",
  "Fitness & Wellness",
  "Music",
  "Religious Institutions",
  "Veterinary Services",
  "Wildlife & Conservation",
  "Interior Design",
  "Event Planning & Management",
  "Maritime",
  "Public Relations",
  "Retail Merchandising",
  "Textiles",
  "Utilities",
  "Waste Management",
  "Zoology",
];

export const services = [
  {
    title: "Performance Management Services",
    description:
      "Our Performance Management services are designed to elevate your organization's efficiency and productivity.",
    icon: <LuGaugeCircle/>,
  },
  {
    title: "AI & Machine Learning in Global HR",
    description:
      "We're pioneering the integration of AI and machine learning technologies into global HR strategies to optimize talent management and improve decision-making processes.",
    icon: <AiOutlinePartition/>,
  },
  {
    title: "Global Staff Outsourcing Solutions",
    description:
      "We understand the unique challenges and opportunities of building a workforce across borders and provide tailored solutions to meet your needs.",
    icon: <BiWorld/>,
  },
  {
    title: "HR Advisory and Consultancy",
    description:
      "We, the OPM Team, are proud to offer comprehensive HR Advisory & Consultancy services to guide your organization through strategic HR initiatives.",
    icon: <LuBadgeHelp />,
  },
  {
    title: "International and Local HR Data Analytics & Reporting",
    description:
      "In the digital age, data is king, and at OPM International HR Consultants, we're dedicated to leveraging data analytics and reporting to drive HR success.",
    icon: <BsBarChartSteps />,
  },
  {
    title: "Change Management & Training Services",
    description:
      "We recognize that successful implementation of HR initiatives hinges on effective change management and training services designed to empower your team.",
    icon: <MdOutlineModelTraining />,
  },
  {
    title: "Global HR Transformation Strategy",
    description:
      "We understand that staying competitive in today's fast-paced business landscape requires a robust Global HR Transformation Strategy.",
    icon: <TbTransform />,
  },
  {
    title: "Addressing Complex HR Processes",
    description:
      "We understand the challenges that businesses face in managing complex HR processes and offer solutions to streamline operations and enhance performance.",
    icon: <MdSettingsInputComponent />,
  },
];
export const softwares = [
  {
    title: "Performance Management Software",
    description:
      "Performance Management Software is designed to help organizations streamline and optimize their performance management processes.",
    icon: "software1.png",
  },
  {
    title: "HRIS (Human Resources Information System)",
    description:
      "HRIS (Human Resources Information System) software is a comprehensive solution designed to manage HR functions efficiently.",
    icon: "software2.png",
  },
  {
    title: "Payroll Software",
    description:
      "Payroll software simplifies and automates the payroll process, ensuring accuracy and compliance with legal standards.",
    icon: "software3.png",
  },
  {
    title: "Performance Management Software",
    description:
      "Performance Management Software is designed to help organizations streamline and optimize their performance management processes.",
    icon: "software1.png",
  },
];
export const products = [
  {
    icon: <RiBarChartGroupedLine />,
    title: "Performance Management Software",
  },
  {
    icon: <BsPeople />,
    title: "HRIS",
  },
  {
    icon: <RiSecurePaymentLine />,
    title: "Payroll software",
  },
  {
    icon: <RiCalendarCheckLine />,
    title: "Attendance",
  },
  {
    icon: <MdOutlineIndeterminateCheckBox />,
    title: "Leave",
  },
  {
    icon: <FaCompress />,
    title: "ESS Portal",
  },
  {
    icon: <GiExpense />,
    title: "Expenses",
  },
  {
    icon: <IoDuplicateOutline />,
    title: "Recruitment",
  },
  {
    icon: <PiArticleNyTimesBold />,
    title: "Timesheet",
  },
];
export const partners = [
  "/partner-logo/101.png",
  "/partner-logo/102.PNG",
  "/partner-logo/103.jpg",
  "/partner-logo/104.png",
  "/partner-logo/105.png",
  "/partner-logo/106.png",
  "/partner-logo/107.JPG",
  "/partner-logo/108.jpg",
  "/partner-logo/109.PNG",
  "/partner-logo/110.jpg",
  "/partner-logo/111.jpg",
  "/partner-logo/112.png",
  "/partner-logo/113.png",
  "/partner-logo/114.jpg",
  "/partner-logo/115.jpg",
  "/partner-logo/116.PNG",
  "/partner-logo/117.png",
  "/partner-logo/118.PNG",
  "/partner-logo/119.png",
  "/partner-logo/120.png",
  "/partner-logo/121.jpg",
  "/partner-logo/11.png",
  "/partner-logo/12.jpg",
  "/partner-logo/13.gif",
  "/partner-logo/14.png",
  "/partner-logo/15.jpg",
  "/partner-logo/16.jpg",
  "/partner-logo/17.png",
];

export const skills = [
  "Communication Skills",
  "Problem-Solving",
  "Teamwork",
  "Time Management",
  "Leadership",
  "Attention to Detail",
  "Adaptability",
  "Critical Thinking",
  "Creativity",
  "Technical Skills (e.g., coding, graphic design, etc.)",
  "Analytical Skills",
  "Customer Service",
  "Project Management",
  "Organizational Skills",
  "Interpersonal Skills",
  "Negotiation Skills",
  "Presentation Skills",
  "Research Skills",
  "Multitasking",
  "Decision Making",
  "Sales Skills",
  "Marketing Skills",
  "Financial Skills",
  "Language Proficiency",
  "Writing Skills",
  "Networking",
  "Conflict Resolution",
  "Emotional Intelligence",
  "Problem Identification",
  "Attention to Detail",
  "Resilience",
  "Self-Motivation",
  "Resourcefulness",
  "Business Acumen",
  "Public Speaking",
  "Social Media Management",
  "Data Analysis",
  "Strategic Planning",
  "Customer Relationship Management (CRM)",
  "Software Proficiency",
  "Technical Writing",
  "Quality Assurance",
  "Risk Management",
  "Training and Development",
  "Continuous Learning",
  "Team Building",
  "Innovation",
  "Ethical Decision Making",
  "Cross-Cultural Competence",
  "Conflict Management",
  "Presentation Design",
  "Event Planning",
  "Budget Management",
  "Forecasting",
  "Market Research",
  "Product Management",
  "Supply Chain Management",
  "Health and Safety Compliance",
  "Legal Knowledge",
  "Human Resources Management",
  "Facilitation",
  "Change Management",
  "Vendor Management",
  "Stakeholder Management",
  "Public Relations",
  "Content Creation",
  "SEO (Search Engine Optimization)",
  "UX/UI Design",
  "Data Visualization",
  "Database Management",
  "Networking Security",
  "Cloud Computing",
  "Mobile Development",
  "Machine Learning",
  "Artificial Intelligence",
  "Blockchain",
  "Internet of Things (IoT)",
  "Cybersecurity",
  "Agile Methodology",
  "Scrum",
  "Kanban",
  "Lean Six Sigma",
  "DevOps",
  "Continuous Integration/Continuous Deployment (CI/CD)",
  "Version Control (e.g., Git)",
  "Web Development",
  "Frontend Development",
  "Backend Development",
  "Full-Stack Development",
  "UI/UX Development",
  "Responsive Design",
  "Mobile App Development",
  "Game Development",
  "AR/VR Development",
  "Embedded Systems",
  "Robotics",
  "Systems Architecture",
  "Network Administration",
  "Database Administration",
  "System Integration",
  "Technical Support",
  "IT Security",
  "Data Science",
  "Data Engineering",
  "Big Data Analytics",
  "Business Intelligence",
  "Predictive Analytics",
  "Data Mining",
  "Statistical Analysis",
  "Quantitative Research",
  "Qualitative Research",
  "Experimental Design",
  "Hypothesis Testing",
  "Regression Analysis",
  "Data Modeling",
  "Machine Learning Algorithms",
  "Natural Language Processing (NLP)",
  "Deep Learning",
  "Computer Vision",
  "Reinforcement Learning",
  "Time Series Analysis",
  "Neural Networks",
  "Data Visualization Tools (e.g., Tableau, Power BI)",
  "Programming Languages (e.g., Python, JavaScript, Java, C++, etc.)",
  "Statistical Software (e.g., R, SAS, SPSS)",
  "Database Management Systems (e.g., SQL, MySQL, PostgreSQL, MongoDB)",
  "Cloud Platforms (e.g., AWS, Azure, Google Cloud)",
  "Web Technologies (e.g., HTML, CSS, JavaScript, Node.js, React, Angular, Vue.js)",
  "Frameworks and Libraries (e.g., Flask, Django, Express, Spring, TensorFlow, PyTorch)",
  "Version Control Systems (e.g., Git, SVN)",
  "Development Tools (e.g., Visual Studio Code, PyCharm, IntelliJ IDEA, Eclipse)",
  "Containerization and Orchestration (e.g., Docker, Kubernetes)",
  "Testing Frameworks (e.g., JUnit, Selenium, Jest, Pytest)",
  "Continuous Integration/Continuous Deployment (CI/CD) Tools (e.g., Jenkins, Travis CI, CircleCI)",
  "Build Automation Tools (e.g., Maven, Gradle, npm, yarn)",
  "Project Management Tools (e.g., Jira, Trello, Asana, Basecamp)",
  "Communication and Collaboration Tools (e.g., Slack, Microsoft Teams, Zoom, Google Meet)",
  "Documentation Tools (e.g., Confluence, Markdown, Google Docs, Microsoft Word)",
  "Productivity Tools (e.g., Microsoft Office Suite, Google Workspace, Evernote)",
  "Remote Work Tools (e.g., VPNs, Remote Desktop Software, Time Tracking Software)",
  "Cybersecurity Tools (e.g., Firewalls, Antivirus Software, Intrusion Detection Systems)",
  "Data Analysis and Visualization Tools (e.g., Excel, Google Sheets, Tableau, Power BI)",
  "Programming Tools (e.g., IDEs, Text Editors, Debugging Tools)",
  "Content Management Systems (CMS) (e.g., WordPress, Drupal, Joomla)",
  "E-commerce Platforms (e.g., Shopify, WooCommerce, Magento)",
  "Customer Relationship Management (CRM) Systems (e.g., Salesforce, HubSpot, Zoho CRM)",
  "Enterprise Resource Planning (ERP) Systems (e.g., SAP, Oracle, Microsoft Dynamics)",
  "Project Management Software (e.g., Microsoft Project, Primavera, Smartsheet)",
  "Accounting Software (e.g., QuickBooks, Xero, FreshBooks)",
  "HR Management Systems (e.g., BambooHR, Workday, ADP)",
  "Business Intelligence (BI) Tools (e.g., Tableau, Power BI, QlikView)",
  "Marketing Automation Platforms (e.g., HubSpot, Marketo, Pardot)",
  "Email Marketing Software (e.g., Mailchimp, Constant Contact, SendGrid)",
  "Social Media Management Tools (e.g., Hootsuite, Buffer, Sprout Social)",
  "Customer Support Software (e.g., Zendesk, Freshdesk, Intercom)",
  "Sales Automation Software (e.g., Salesforce Sales Cloud, Pipedrive, Zoho CRM)",
  "Inventory Management Systems (e.g., TradeGecko, Fishbowl, Cin7)",
  "Point of Sale (POS) Systems (e.g., Square, Toast, Shopify POS)",
  "Supply Chain Management (SCM) Software (e.g., SAP SCM, Oracle SCM, Kinaxis)",
  "Warehouse Management Systems (WMS) (e.g., Manhattan Associates, HighJump, SAP EWM)",
  "Transportation Management Systems (TMS) (e.g., MercuryGate, Oracle OTM, JDA TMS)",
  "Field Service Management (FSM) Software (e.g., Salesforce Field Service, ServiceMax, Workiz)",
  "Fleet Management Software (e.g., GPS Insight, Verizon Connect, Fleet Complete)",
  "Construction Management Software (e.g., Procore, PlanGrid, Autodesk Construction Cloud)",
  "Property Management Software (e.g., Yardi, AppFolio, Buildium)",
  "Hospitality Management Software (e.g., Oracle Hospitality, Cloudbeds, innRoad)",
  "Healthcare Information Systems (e.g., Epic Systems, Cerner, Allscripts)",
  "Electronic Health Records (EHR) Systems (e.g., Epic EHR, Cerner EHR, Meditech)",
  "Practice Management Software (e.g., Athenahealth, Kareo, AdvancedMD)",
  "Telemedicine Platforms (e.g., Teladoc Health, Amwell, Doxy.me)",
  "Medical Billing Software (e.g., AdvancedMD, Kareo, CollaborateMD)",
  "Dental Practice Management Software (e.g., Dentrix, Eaglesoft, Open Dental)",
  "Veterinary Practice Management Software (e.g., AVImark, ezyVet, VetBlue)",
  "Pharmacy Management Systems (e.g., McKesson Pharmacy Systems, Rx30, Liberty Software)",
  "Electronic Medical Records (EMR) Software (e.g., Epic EMR, Cerner EMR, Meditech EMR)",
  "Health Information Exchange (HIE) Systems (e.g., CommonWell Health Alliance, Carequality, DirectTrust)",
  "Remote Patient Monitoring (RPM) Systems (e.g., BioTel Care, iHealth Labs, Philips)",
  "Clinical Decision Support (CDS) Systems (e.g., Zynx Health, Elsevier ClinicalKey, Cerner)",
  "Medical Imaging Systems (e.g., GE Healthcare, Siemens Healthineers, Philips Healthcare)",
  "Laboratory Information Management Systems (LIMS) (e.g., Thermo Fisher Scientific, LabWare, LabVantage)",
  "Radiology Information Systems (RIS) (e.g., Merge Healthcare, GE Healthcare, Fujifilm Medical Systems)",
  "Picture Archiving and Communication Systems (PACS) (e.g., GE Healthcare, McKesson, Agfa HealthCare)",
  "Telehealth Software (e.g., Doximity, eClinicalWorks, Mend)",
  "E-prescribing Software (e.g., Surescripts, DrFirst, Practice Fusion)",
  "Revenue Cycle Management (RCM) Software (e.g., Waystar, Availity, Change Healthcare)",
  "Patient Engagement Software (e.g., Phreesia, Relatient, PatientPoint)",
  "Health Information Management (HIM) Software (e.g., Ciox Health, MRO Corporation, Iron Mountain)",
  "Clinical Trial Management Systems (CTMS) (e.g., Medidata Solutions, Oracle Health Sciences, Veeva Systems)",
  "Electronic Data Capture (EDC) Systems (e.g., OpenClinica, Medidata Rave, REDCap)",
  "Regulatory Compliance Software (e.g., ProPharma Group, MasterControl, QUMAS)",
  "Pharmacovigilance Software (e.g., Oracle Argus, ArisGlobal, Veeva Vault)",
  "Clinical Data Management (CDM) Software (e.g., SAS Clinical Data Integration, Oracle Clinical, IBM Clinical Development)",
  "Laboratory Information System (LIS) (e.g., Epic Beaker, Sunquest, Cerner Millennium PathNet)",
  "Blood Bank Information System (BBIS) (e.g., Mediware HCLL Blood Bank, Haemonetics Blood Bank, SCC Soft Blood Bank)",
  "Hospital Management Software (e.g., Epic Systems, Cerner, Allscripts)",
  "Medical Practice Management Software (e.g., AdvancedMD, Kareo, Athenahealth)",
  "Healthcare Analytics Software (e.g., IBM Watson Health, Optum, SAS Health Analytics)",
  "Patient Scheduling Software (e.g., Kareo, SimplePractice, TheraNest)",
  "Telemedicine Software (e.g., Doxy.me, eVisit, Mend)",
  "Mental Health Software (e.g., TherapyNotes, SimplePractice, TheraNest)",
  "EHR Integration Software (e.g., Redox, Health Gorilla, 1upHealth)",
  "Patient Portal Software (e.g., NextGen Healthcare, athenahealth, Kareo)",
  "Medical Billing Services (e.g., AdvancedMD, CareCloud, Athenahealth)",
  "Dental Practice Management Services (e.g., RevenueWell, Solutionreach, Revenue Cycle Management)",
  "Healthcare Consulting Services (e.g., Deloitte, PwC, Accenture)",
  "Healthcare Staffing Agencies (e.g., AMN Healthcare, Cross Country Healthcare, Maxim Healthcare Services)",
  "Healthcare Revenue Cycle Management (RCM) Services (e.g., Waystar, R1 RCM, Change Healthcare)",
  "Healthcare IT Services (e.g., Cerner, Epic Systems, Allscripts)",
  "Healthcare Managed Services (e.g., AT&T, IBM, Cognizant)",
  "Healthcare Credentialing Services (e.g., Verisys Corporation, ApprioHealth, Aperture Credentialing)",
  "Medical Billing Outsourcing Companies (e.g., AdvancedMD, GeBBS Healthcare Solutions, Kareo)",
  "Telemedicine Services (e.g., Teladoc Health, Doctor On Demand, Amwell)",
  "Healthcare Marketing Agencies (e.g., Cardinal Digital Marketing, Healthcare Success, Scorpion Healthcare)",
  "Revenue Cycle Management (RCM) Outsourcing Companies (e.g., GeBBS Healthcare Solutions, R1 RCM, nThrive)",
  "Healthcare Compliance Services (e.g., The Joint Commission, Healthcare Compliance Pros, Clearwater Compliance)",
  "Medical Coding Services (e.g., Aviacode, AMN Healthcare, Maxim Healthcare Services)",
  "Health Information Management (HIM) Outsourcing Companies (e.g., MRO Corporation, Ciox Health, Sharecare Health Data Services)",
  "Medical Transcription Services (e.g., MModal, Nuance Communications, Acusis)",
  "Revenue Cycle Management (RCM) Software Companies (e.g., Waystar, athenahealth, Kareo)",
  "Medical Billing Software Companies (e.g., AdvancedMD, CareCloud, CollaborateMD)",
  "Healthcare Analytics Companies (e.g., IBM Watson Health, Optum, SAS Health Analytics)",
  "Telemedicine Companies (e.g., Teladoc Health, Amwell, Doximity)",
  "Medical Software Companies (e.g., Epic Systems, Cerner, Allscripts)",
  "EHR Companies (e.g., Epic Systems, Cerner, Allscripts)",
  "Healthcare Consulting Firms (e.g., Deloitte, PwC, Accenture)",
  "Healthcare Technology Companies (e.g., Philips, Siemens Healthineers, GE Healthcare)",
  "Healthcare IT Companies (e.g., Cerner, Epic Systems, Allscripts)",
  "Healthcare Staffing Companies (e.g., AMN Healthcare, Cross Country Healthcare, Maxim Healthcare Services)",
  "Medical Billing Companies (e.g., GeBBS Healthcare Solutions, R1 RCM, nThrive)",
  "Telehealth Companies (e.g., Teladoc Health, Doctor On Demand, Amwell)",
  "Healthcare Marketing Companies (e.g., Cardinal Digital Marketing, Scorpion Healthcare, Healthcare Success)",
  "Healthcare Compliance Companies (e.g., The Joint Commission, Healthcare Compliance Pros, Clearwater Compliance)",
  "Medical Coding Companies (e.g., Aviacode, Maxim Healthcare Services, AMN Healthcare)",
  "Health Information Management (HIM) Companies (e.g., MRO Corporation, Ciox Health, Sharecare Health Data Services)",
  "Medical Transcription Companies (e.g., MModal, Nuance Communications, Acusis)",
];
